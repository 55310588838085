.wrapper {}

.img {
  width: 237px;
  height: 330px;
  flex-shrink: 0;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
  object-position: center;
}

.title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 600;
  line-height: 140%;
  cursor: pointer;
}