.TextArea {
  position: relative;
  width: 100%;

  border-radius: 15px;


  label {
    z-index: 1;
    color: var(--grey-text);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    position: absolute;
    top: 10px;
    left: 20px;
    pointer-events: none;
  }



  &_wrap {
    & textarea {
      min-height: 106px;
      position: relative;
      display: flex;
      width: 100%;
      align-items: flex-start;
      gap: 10px;
      font-size: 16px;
      border-radius: 15px;
      padding: 40px 20px 20px 20px;
      background: var(--2-grey);
      border: none;
      resize: none;
      color: var( --black-text);
      &:focus {
        outline: none;
        border: none;
      }

    }
  }

  &.invalid {

    textarea {
      border: 1px solid red;
      background: var(--2-grey);
      color: red;
    }

    &>span {
      display: inline-block;
      margin-top: 8px;
      color: red;
      font-size: 12px;
    }

    .TextArea {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 15px;
        z-index: 1;
        margin: auto 0;

        width: 10px;
        height: 10px;
      }
    }
  }
}