.App {
  background: var(--bg-grey);
margin: auto;
width: 100%;
}

/* src/fonts.css */

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 100;
  src: url('../public/fonts/NotoSans-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 100;
  src: url('../public/fonts/NotoSans-ThinItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 200;
  src: url('../public/fonts/NotoSans-ExtraLight.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 200;
  src: url('../public/fonts/NotoSans-ExtraLightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../public/fonts/NotoSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 300;
  src: url('../public/fonts/NotoSans-LightItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../public/fonts/NotoSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 400;
  src: url('../public/fonts/NotoSans-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../public/fonts/NotoSans-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 500;
  src: url('../public/fonts/NotoSans-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../public/fonts/NotoSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 600;
  src: url('../public/fonts/NotoSans-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../public/fonts/NotoSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../public/fonts/NotoSans-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 800;
  src: url('../public/fonts/NotoSans-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 800;
  src: url('../public/fonts/NotoSans-ExtraBoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 900;
  src: url('../public/fonts/NotoSans-Black.ttf') format('truetype');
}

@font-face {
  font-family: 'Noto Sans';
  font-style: italic;
  font-weight: 900;
  src: url('../public/fonts/NotoSans-BlackItalic.ttf') format('truetype');
}
