.change {
  padding-top: 20px;
  position: relative;
  flex-direction: column;
  display:flex;
  height: 100%;
  justify-content: space-between;
  &_wrp{

  }
}

.info {
  display: grid;
  gap: 25px;
  text-align: start;
}

.input {
  max-width: 446px !important;
}