.Input {
  position: relative;
  width: 100%;

  .InputWrap {
    position: relative;
    background: var(--2-grey);

    border-radius: 15px;
  }

  &_label {
    position: absolute;
    color: var(--grey-text);
    font-size: 14px;
    font-weight: 500;
    line-height: 140%;
    padding: 10px 20px 0 20px;
    pointer-events: none;
    z-index: 10;
    &_wrp{
      input {
        background: fixed;
        border-radius: 15px;
        padding: 3px 0 0 0px;
        width: 100%;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        padding: 31px 20px 17px 20px !important;
      }
    }

  }

  label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    color: black;

  }

  input {
    background: fixed;
    border-radius: 15px;
    padding: 3px 0 0 0px;
    width: 100%;
    border: none;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    padding: 19px 20px 19px 20px;
    color: var( --black-text);


    &::placeholder {
      color: var(--Grey);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 6px rgba(71, 164, 227, 0.08);
    }

    &[disabled] {
      border: none;
    }

    &[type="number"] {
      -moz-appearance: textfield;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }

    &[type="time"] {

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }

  &.required {

    label {
      position: relative;

      &::before {
        position: absolute;
        content: '*';
        top: -1px;
        right: -7px;
      }
    }
  }

  &.valid {
    input {
      position: relative;

    }

    .InputWrap {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 15px;
        z-index: 1;
        margin: auto 0;
        width: 13px;
        height: 9px;
      }
    }
  }

  &.invalid {
    input {
      background: fixed;
      border-radius: 15px;
      padding: 3px 0 0 0px;
      width: 100%;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      padding: 19px 20px 19px 20px;
      color: #FF1C45;
      border-radius: 15px;
    }

    .InputWrap {
      position: relative;
      border: 1px solid #FF1C45;

      &::before {
        position: absolute;
        content: '';
        top: 0;
        bottom: 0;
        right: 15px;
        z-index: 1;
        margin: auto 0;
        width: 10px;
        height: 10px;
      }
    }
  }

  &>span {
    width: 100%;
    text-align: left;
    display: inline-block;
    color: #FF1C45;
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    /* 22.4px */
    margin-top: 4px;
  }
}

.label {
  color: var(--Black);
  font-size: 14px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 20px;
}

.password {
  position: absolute;
  display: flex;
  cursor: pointer;
  width: 24px;
  height: 24px;
  top: 0;
  bottom: 0;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  background: center/cover no-repeat url("../../../../assets/img/hide.svg");

}