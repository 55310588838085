.react-datepicker-wrapper {
  display: flex;
  width: 100%;
  position: relative;

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--black-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: var(--grey-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: var(--grey-text);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }


  & input {
    display: flex;
    position: relative;
    box-sizing: border-box;
    background: var(--2-grey, #F8F8F8) !important;
    display: flex;
    width: 100%;
    border-radius: 15px;
    padding: 19px 59px;
    align-items: flex-start;
    border: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;

    &.invalid {
      color: #FF1C45;
      border: 1px solid #FF1C45;
      &::placeholder {
        color: #FF1C45;
      }
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
    z-index: 1;

    width: 24px;
    height: 24px;
    pointer-events: none;
    background: center/cover no-repeat url("../../../../assets/img/calendar.svg");
  }

  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 1;

    width: 22px;
    height: 22px;
    pointer-events: none;
    background: center no-repeat url("../../../../assets/img/arrow.svg");
  }

}

.react-datepicker {
  display: flex;
  width: 100%;
  z-index: 999;

}