.item {
   max-width: 493px;
    height: fit-content;
    padding: 30px;
    gap: 10px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    cursor: pointer;
    &_img {
        max-height: 153px;
        object-fit: contain;
        border-radius: 20px;
        width: 100%;
    }

    &_price {
        border-radius: 26px;
        background: var(--Yellow-Light, #FFEAC1);
        padding: 6px 15px;
        gap: 10px;
        max-width: fit-content;
        color: var(--Yellow, #F6A80B);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
        margin-bottom: 10px;
    }

    &_title {

        color: var(--Black);
        font-size: 22px;
        font-weight: 700;
        line-height: 140%;
        text-decoration-line: none;

        &:hover {
            color: var(--Yellow, #F6A80B);
            font-size: 22px;
            font-weight: 700;
            line-height: 140%;
            text-decoration-line: underline;
        }
    }

    &_text {
        margin-top: 20px;
        line-height: 160%;
        margin-bottom: 30px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}