.DarkBackground {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;

  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items:center;

  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);

  padding: 15px;

  background-color: rgba(0, 0, 0, .4);
  animation: toggleDarkBackground .2s ease-in;
}
