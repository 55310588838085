
.wrapper {
  background: #FFFFFF;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  @media(min-width: 992px) {
    border-radius: 20px;
  }
}

.head {
 // border-bottom: 1px solid $gray200;
  padding: 24px 16px 15px;
  @media(min-width: 576px) {
    padding: 24px 32px 15px;
  }
  @media(min-width: 992px) {
    padding: 40px 40px 20px;
  }
}

.title {
  font-size: 24px;
  margin: 0;
  line-height: 1.3;
  @media(min-width: 992px) {
    font-size: 34px;
  }
}

.body {
  padding: 25px 16px 30px;
  @media(min-width: 576px) {
    padding: 25px 32px 32px;
  }
  @media(min-width: 992px) {
    padding: 25px 40px 40px;
  }
}

.content {
  font-size: 14px;
  line-height: 1.5;
  @media(min-width: 992px) {
    font-size: 16px;
  }

  p, ol, ul {
    margin: 0 0 1.5rem;
    img {
      vertical-align: baseline;
    }
  }

  h1, h2, h3 {
    margin: 0 0 0.5rem;
  }

  h2 {
    font-size: 20px;
    @media(min-width: 992px) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 16px;
    @media(min-width: 992px) {
      font-size: 20px;
    }
  }

  ul {
    li {
      img {
        vertical-align: baseline;
      }
    }
  }
}