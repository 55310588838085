.wrap {
  padding-top: 14px;

  height: 100%;
}




.info {
  width: 100%;

  & p {
    margin: 0;
  }
}

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dde7ed;
  flex-shrink: 0;
}

.links {
  flex: 1 1 auto;
}

.list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;

  box-sizing: border-box;

  &_item {
    


    position: relative;
    width: 100%;

    &_noactive {
      display: flex;
      width: 100%;
      text-decoration: none;
      color: var(--black-text);
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      padding: 14px 0 14px 30px;
    }

    &_active {
      padding: 14px 0 14px 30px;
      display: flex;
      align-items: center;
      text-decoration: none;
      color: var(--black-text);
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;
      &:after {
        position: absolute;
        right: 0;
        content:"";
        display:block;
        width:4px;
        height:50px;
        border-top-left-radius: 50px;
        border-bottom-left-radius: 50px;
        background: var(--yellow);
        position:absolute;

      }
    }
  }
}