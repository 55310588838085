.modal {
  width: 100%;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--border-grey);;

  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: left;
    align-items: flex-start;



    &_text_gray {
      color: var(--grey-text);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration: none;
    }

    &_btn {
      display: flex;
      width: 100%;
      padding: 19px 45px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: var(--yellow);
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
      margin-top: 30px;
      cursor: pointer;
    }

    &_link {
      color: var(--yellow, #F6A80B);
      text-decoration: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;

      &_gray {
        color: #BBB;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        text-decoration: none;
        cursor: pointer;
      }

      &_wrp {
        display: flex;
        justify-content: center;
        gap: 60px;
        padding-top: 30px;
      }
    }
  }

  &_header {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 700;
    line-height: 160%;
    border-bottom: 1px solid var(--border-grey);;
    padding-bottom: 20px;
    margin-bottom: 30px;

    &_btn_return {
      display: flex;
      align-items: center;
      color: var(--yellow, #F6A80B);
      font-size: 16px;
      font-weight: 600;
      line-height: 140%;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        margin-right: 10px;
        background: center/cover no-repeat url("../../../assets/img/btnReturn.svg");
      }
    }
  }
}
.error{
  color: #FF1C45;
}
