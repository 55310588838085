.music {
  &_field {
    margin-top: 30px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);

    min-height: 370px;
    padding: 30px;


    &_img {
      width: 100%;
      border-radius: 15px;
      object-fit: cover;

      @media (max-width: 460px) {
        height: 140px;
        width: 140px;
       }
    
      &_mask{
        position: relative;
        &_wrp{
          border-radius: 15px;
          @media (max-width: 460px) {
            height: 140px;
            width: 140px;
           }
        }
        :before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("../../../../assets/img/play.svg") no-repeat center center;
          opacity: 0;
          z-index: 1;
          transition: opacity 0.4s ease;
      }
      :after {
        border-radius: 15px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, .4);
          opacity: 0;
          transition: opacity 0.4s linear;
      }
      :hover:before,
      :hover:after {
          opacity: 1;
      }
      }
    }

    &_no_img {
      height: 180px;
      width: 180px;
      background: var(--Grey-Light, #F4F4F4);
      border-radius: 15px;
      @media (max-width: 460px) {
        height: 140px;
        width: 140px;
       }
    }

    &_item {
    display: flex;




    flex-direction: column;
      cursor: pointer;
      &_title {
        max-width: 180px;

        color: var(--Black);
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        margin-top: 15px;

      }
      @media (max-width: 460px) {
        max-width: 130px;
       }

    }


  



    &_wrp {
      gap: 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

      &_music {

        gap: 18px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &_btn {
        cursor: pointer;
        color: var(--Yellow, #F6A80B);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &_title {
      color: var(--Black);
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;

    }
  }
}