.news {
    max-width: 365px;
    width: 100%;
    height: 400px;
    flex-shrink: 0;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    cursor: pointer;
    margin: auto;
    display: flex;
    flex-direction: column;
    @media(max-width: 756px) {
        max-width: 320px;
       // padding: 0 10px;
      }

    @media(max-width: 652px) {
        max-width: 100%;
        padding: 0 10px;
      }

    &_title {
        font-size: 18px;
        font-weight: 500;
        line-height: 160%;
        padding: 15px 20px 0 20px ;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

    }
    &_img {
        width: 100%;
        height: 220px;
        @media(max-width: 652px) {
            height: 270px;

          }
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        object-fit: cover;
    }
    &_date {
        padding: 36px 20px 0 20px ;
        color: var(--grey-text);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
    }
}