.support {
  padding-top: 20px;
  position: relative;
  flex-direction: column;
  display:flex;
  height: 100%;
  justify-content: space-between;
  &_wrp{
padding-right: 10px;
  }
}

.info {
  display: flex;
flex-wrap: wrap;
  gap: 25px;

  &_wrp {
    display: grid;
    gap: 25px;
  }
}

.title {
  text-align: start;
  color: var(--black-text);
  font-size: 22px;
  font-weight: 700;
  line-height: 140%;
  margin-bottom: 5px;
}

.input{
  width: 48%;
  max-width: 100%;
  @media(max-width: 1024px) {
    width: 100%;
  }
}