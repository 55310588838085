.modal {
  width: 100%;
  &_search {
    width: 100%;
    border-radius: 15px;
    background: var(--2-Grey );
    height: 60px;

    &_wrp {
      margin-bottom: 20px;
    }
  }

  &_music {

    &_title {
      color: var(--Black);
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
    }

    &_wrp{
      margin-top: 30px;
      display: grid;
      gap: 20px;
      max-height: 690px;
      overflow: auto;
    }
    &_item {
      position: relative;
      display: flex;
      gap: 15px;
      max-height: 70px;
      cursor: pointer;
      &_img {
        height: 70px;
        width: 70px;
      }

      &_no_img {
        height: 70px;
        min-width: 70px;
        display: flex;
        border-radius: 15px;
        background: var(--Grey-Light, #F4F4F4);
        align-items: center;
        justify-content: center;

        &:before {
          position: absolute;
          content: '';
          width: 32.667px;
          height: 32.667px;
          background: center/cover no-repeat url("../../../assets/img/noMusic.svg");
        }
      }

      &_wrp {
        height: 70px;
        padding: 10px 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

      }
    }
  }
}

.tinny{
  position: fixed;
  right: 10px; 
  bottom: 20px;
  padding: 10px; /* Поля вокруг текста */ 
  background: white;
  border: 1px solid gray;
  width: 500px;
  z-index: 100;
  border-radius: 20px;
}


