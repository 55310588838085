.audioPlayer {
    --secondary: #ffd200;
    --roundedCorner: 10px;
    align-items: center;
    display: flex;
    width: 100%;
    
  }
  
  .forwardBackward {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    font-family: monospace;
    font-size: 16px;
    cursor: pointer;
  }
  
  .forwardBackward:hover {
    color: var(--primary);
  }
  
  .playPause {
    background: white;
    position: absolute;
    left: 29px;
    top:29px;
    border: none;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-size: 32px;
    color: var(--secondary);
    display: flex;
    justify-content: center;
    align-items:center;
  }
  
  .play {
cursor: pointer;
    width: 10px;
  }
  .stop {
    cursor: pointer;
        width: 10px;
      }
  
  .currentTime,
  .duration {
   font-size: 12px; 
  }
  
  .currentTime {

  }
  
  .progressBar {
    --bar-bg: #ffe3d4;
    --seek-before-width: 0;
    --seek-before-color: #ffc2a1;

    --selectedKnobby: #26c9c3;
  
    appearance: none;
    background: var(--bar-bg);;
    border-radius: var(--roundedCorner);
    position: relative;
    width: 100%;
    height: 11px;
    outline: none;
    cursor: pointer;
  }
  
  /* progress bar - safari */
  .progressBar::-webkit-slider-runnable-track {
    background: var(--bar-bg);;
    border-radius: var(--roundedCorner);
    position: relative;
    width: 100%;
    height: 11px;
    outline: none;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-track {
    background: var(--bar-bg);;
    border-radius: var(--roundedCorner);
    position: relative;
    width: 100%;
    height: 11px;
    outline: none;
  }
  
  .progressBar::-moz-focus-outer {
    border: 0;
  }
  
  /* progress bar - chrome and safari */
  .progressBar::before {
    content: '';
    height: 11px;
    width: var(--seek-before-width);
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--roundedCorner);
    border-bottom-left-radius: var(--roundedCorner);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
  }
  
  /* progress bar - firefox */
  .progressBar::-moz-range-progress {
    background-color: var(--seek-before-color);
    border-top-left-radius: var(--roundedCorner);
    border-bottom-left-radius: var(--roundedCorner);
    height: 11px;
  }
  
  /* knobby - chrome and safari */
  .progressBar::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: none;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    margin: -2px 0 0 0;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - chrome and safari */
  .progressBar:active::-webkit-slider-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  
  /* knobby - firefox */
  .progressBar::-moz-range-thumb {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    border: transparent;
    background-color: var(--knobby);
    cursor: pointer;
    position: relative;
    z-index: 3;
    box-sizing: border-box;
  }
  
  /* knobby while dragging - firefox */
  .progressBar:active::-moz-range-thumb {
    transform: scale(1.2);
    background: var(--selectedKnobby);
  }
  
  .progressBarWrapper {
    display: flex;
    position: relative;
    margin-top: 10px;

    width: 300px;
  }

  .item{
    display: flex;
    flex-direction: column;
  }

  .img{
    height: 70px;
    width: 70px;

  }
  .item {
    width: 100%;
    display: flex;
    gap: 15px;
    flex-direction: row;



    &_img {
      height: 70px;
      width: 70px;
      cursor: pointer;
    }

    &_no_img {
      height: 70px;
      min-width: 70px;
      display: flex;
      border-radius: 15px;
      background: var(--Grey-Light, #F4F4F4);
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &_name {
      color: var(--grey-text);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }



    &_title {
      color: var(--Black);
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }
  }

  
  .chapter {
    display: block;
    height: 11px;
    background-color: var(--primary);
    position: absolute;
    top: 4px;
    left: var(--left);
    width: var(--width);
    pointer-events: none;
    z-index: 2;
  }
  
  .chapter.start {
    border-top-left-radius: var(--roundedCorner);
    border-bottom-left-radius: var(--roundedCorner);
  }
  
  .chapter.end {
    border-top-right-radius: var(--roundedCorner);
    border-bottom-right-radius: var(--roundedCorner);
  }