.Modal {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
  height: auto;
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 30px 25px;
  border-radius: 30px;
  border: 1px solid var(--border-grey);
  background: var(--white);
  z-index: 100000;
  @media(max-width: 1024px) {
    border-radius: 10px;
  }
}

.closeBtn {
  position: absolute;
  top: -5px;
  border-radius: 50px;
  right: -65px;
  outline: none;
  border: none;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media(max-width: 687px) {
    right:15px;
    top:15px;
        width: 11px;
    height: 11px;
    background: center/cover no-repeat url("../../../assets/img/close-gray.svg") !important;
  }

  &:before {
    content: '';
    width: 11px;
    height: 11px;
    background: center/cover no-repeat url("../../../assets/img/close.svg");
  }
}