.profile {
  padding-top: 20px;
  position: relative;
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: space-between;


}

.user {
  display: flex;
  align-items: center;
  gap: 30px;
  text-align: start;
  margin-bottom: 50px;

  @media(max-width: 668px) {
    justify-content: center;
  }

  &_name {
    color: var(--black-text);
    font-size: 22px;
    font-weight: 700;
    line-height: 140%;
    margin-bottom: 6px;
    /* 30.8px */
  }

  &_link {
    color: var(--blue, #397FEF);
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    /* 22.4px */
  }
}

.info {
  display: flex;
  grid-gap: 25px;
  flex-wrap: wrap;

  @media(max-width: 668px) {
    justify-content: center;
  }
}

.input {
  max-width: 446px;
}
.btn_wrp{
  @media(max-width: 1256px) {
    padding: 20px
  }
}