

.stream-shedule .program {
  display: flex;    
}

.stream-shedule .time {
  height: 13px;
  width: 45px;
}

.stream-shedule .info {
  height: 15px;
  width: 93px;
}


.program-list .program {
  margin-top: 30px;
}

.program-list .info-wrapper {
  margin-left: 30px;
  width: 300px;
}
.program .name {
  width: 300px;
  display: inline-block;
}

.program .now {
  width: 300px;
  color: var(--yellow, #F6A80B);
  display: inline-block;
  text-align: start;
  margin-top: 5px;
}

.program .time,
.program .name {
  color: #bbbbbb;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.program-list .program-active .time,
.program-list .program-active .name {
}



.program-list .time {
}

.program-list .name {
}

.program-list .now {
}

.progress-bar-container {
  margin-top: 15px;
  width: 95%;
  height: 6px;
  background-color: #ccc;
  border-radius: 3px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: #F6A80B;
  border-radius: 3px;
  
}
