.wrp {
  margin: auto;
  max-width: 1007px;
}



.header {
  display: flex;
  padding-bottom: 60px;
  margin-bottom: 24px;
  width: 100%;

  &_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_wrp {
      width: 100%;
    }
  }

  &_description {
    margin-top: 21px;
    color: var(--Black-Text, #2F2F30);
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    max-width: 570px;
  }
}

.avatar {
  margin-right: 30px;
  min-width: 150px;
}

.counter {
  &_wrp {
    display: flex;
    gap: 30px;
  }

  &_value {
    font-size: 18px;
    margin-right: 10px;
    font-weight: 700;
    line-height: 140%;
    /* 25.2px */
  }
}


.post {
  &_wrp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;


  }

  &_item {
    cursor: pointer;
    object-fit: cover;
    width: 322.203px;
    border: 1px solid var(--border-grey);
    height: 322.203px;
  }
}

.blocks {

  &_wrp {
    border-top: 1px solid var(--border-grey);
  }

  &_titles {
    padding-top: 20px;
  }

}

.posts {
  display: flex;
  align-items: center;
  color: var(--Black-Text, #2F2F30);
  font-size: 16px;
  font-weight: 500;

  &:before {
    margin-right: 15px;
    content: '';
    width: 32px;
    height: 32px;
    background-image: url("../../assets/img/posts.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
}

.add{
  height: 15px;
  width: 150px;
  font-size: 13px;
}