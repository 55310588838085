.wrapper {
display: flex;
flex-wrap: wrap;
justify-content: center;
max-width: 1600px;
gap: 20px;
}

.content {
  margin-top: 15px;
  margin-bottom: 60px;
}