.main {
  &_title {
    color: var(--Black);
    font-size: 28px;
    font-weight: 700;
    line-height: 160%;
    margin: 50px 0 30px 0;

    @media(max-width: 1023px) {
      text-align: center;
    }
    @media(max-width: 668px) {
      margin: 25px 0 10px 0;
    }
  }

  &_links {
    display: flex;
    flex-wrap: wrap;
    grid-template-columns: 1fr;



    @media(min-width: 1024px) {
      grid-template-columns: 1fr 1fr;
    }

    &_slider {
      margin: auto;
      margin-bottom: 20px;
      display: flex;
      width: 100%;
      max-width: 750px;

      @media(max-width: 767px) {
        padding: 0 10px;
      }
      
      @media(max-width: 404px) {
        padding: 0;

      }


    }

    &_item {
      cursor: pointer;
      display: flex;


      &>svg {
        max-width: 100%;
        @media(max-width: 670px) {
          max-height: 180px;
        }
        
      @media(max-width: 550px) {
        max-height: 150px;
      }
      @media(max-width: 475px) {
        max-height: 130px;
      }
      @media(max-width: 404px) {
        max-height: 100%;
      }
      }
    }


    &_wrp {
      margin: auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: 750px;
      row-gap: 20px;
      column-gap: 28px;

      @media(max-width: 767px) {
        row-gap: 7px;
        column-gap: 5px;
        padding: 0 10px;
      }

      @media(max-width: 404px) {
        padding: 0 5px;
        grid-template-columns: 1fr;

      }

      @media(min-width: 1024px) {
        margin-top: 0;
      }
    }
  }

  &_videos {
    margin: auto;
    @media(max-width: 1508px) {
      max-width: 755px;
    }
    &_wrp {
      display: flex;
      flex-wrap: wrap;

      margin: auto;

    @media(max-width: 767px) {
      padding: 0 10px;
    }
    
    @media(max-width: 404px) {
      flex-wrap: wrap;

    }

      @media(max-width: 1023px) {
        justify-content: center;
      }

      gap: 19px
    }

    &_item {
      cursor: pointer;
    }
  }

  &_news {
    @media(max-width: 1508px) {
      max-width: 750px;
      margin: auto;
    }

    &_wrp {
      @media(max-width: 765px) {
       gap:5px
      }
  margin: auto;
      display: flex;
     flex-wrap: wrap;
      gap: 19px
      
    }

    &_item {
      width: 100%;
      cursor: pointer;
    }

    &_btn {
      &_wrp {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      border-radius: 14px;
      background: fixed;
      border: 1.5px solid var(--Yellow, #F6A80B);
      color: var(--black-text);
      font-size: 16px;
      font-weight: 500;
      padding: 10px 20px;
      cursor: pointer;
      margin-top: 30px;

      &:hover {
        transition: 0.2s;
        color: white;
        background-color: #F6A80B;
      }

    }
  }

  &_top {
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    min-height: 370px;
    padding: 30px;

    &_wrp {
      gap: 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &_btn {
        cursor: pointer;
        color: var(--Yellow, #F6A80B);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &_title {
      color: var(--Black);
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;
    }
  }

  &_live {
    // margin-bottom: 120px;
    @media(max-width: 1508px) {
      max-width: 750px;
      margin: auto;
    }

    &_wrp {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      display: grid;
      padding: 0 5px;

      @media(max-width: 1024px) {
        grid-template-columns: 1fr;
      }
    }
  }

  &_forUser {
    margin-top: 30px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    min-height: 370px;
    padding: 30px;

    &_wrp {
      gap: 18px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &_btn {
        cursor: pointer;
        color: var(--Yellow, #F6A80B);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;

      }
    }

    &_title {
      color: var(--Black);
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;
    }
  }

  &_musics {
    @media(max-width: 1508px) {
      max-width: 750px;
    }

    margin: auto;

    &_wrp {

      margin: auto;
      display: flex;
      max-height: 100px;
     // flex-wrap: wrap;
 
    }

    &_item {
      cursor: pointer;
      display: flex;
      max-width: 365px;
      width: 100%;
      &>svg {
        max-width: 100%;
        @media(max-width: 700px) {
          max-height: 160px;
        }
        @media(max-width: 475px) {
          max-height: 130px;
        }
        @media(max-width: 404px) {
          max-height: 100%;
        }
      }
    }
  }
}