.filter {
  max-width: 1520px;
  height: 325px;
  flex-shrink: 0;
  border-radius: 30px;
  padding: 30px;
  border: 1px solid var(--border-grey);
  background: var(--white);
  margin-bottom: 60px;

  &_wrp {
    display: flex;
    gap: 20px;
    margin-top: 30px;
  }

  &_select {
    display: flex;
    width: 276px;

    svg {
      margin-right: 9px;
    }

    &>div {
      height: 70px !important;
      border-radius: 14px !important;
      border-radius: 14px;
      border: 1px solid var(--border-grey) !important;
      background: var(--2-grey, #F8F8F8) !important;
      display: flex !important;
      align-items: center;
      padding: 20px !important;

      &>span {
        color: var(--Black-Text, #2F2F30) !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        line-height: 140% !important;

        &>input {
          padding: 9px !important;
          height: 100% !important;
          width: 100% !important;
          display: flex !important;
          align-items: center;
        }
      }
    }

    border-radius: 14px !important;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-grey);
    background: var(--2-grey);

    &::before {
      display: none;
    }
  }

  &_title {
    font-size: 28px;
    font-weight: 700;
    line-height: 160%;
    margin-bottom: 10px;
    /* 44.8px */
  }

  &_gray {
    color: var(--grey-text);
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
    /* 28.8px */
  }
}

.clear {
  margin-top: 25px;
  color: var(--Grey-Text, #BBB);
  font-size: 16px;
  font-weight: 500;
  line-height: 140%;
  display: flex;
  align-items: center;
  cursor: pointer;
  &:before {
    margin-right: 10px;
    content: '';
    width: 32px;
    height: 32px;
    background: center/cover no-repeat url("../../assets/img/clear.svg");
  }
}