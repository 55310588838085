.rhap_play-pause-button{
position: absolute;
left: 15px;
top:13px;
padding: 0 !important;
margin: 0 !important;

    
}

.rhap_repeat-button{
    display: none;
}

.rhap_volume-controls{
    display: none !important;
    
}
.rhap_controls-section{
    margin: 0 !important;
}

.rhap_progress-indicator{
    display: none;
}

.rhap_container{
    padding: 0;
    
}