.CustomDatePicker {
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  font-weight: 300;
  font-size: 14px;
  background: var(--2-grey, #F8F8F8) !important;
  border-radius: 15px; 
}

.error {
  color: #FF1C45;
  font-size: 14px;
  margin-top: 4px;
}

.wrapper {
  width: 100%;
}