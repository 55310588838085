.horoscope {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: auto;
  justify-content: center;

  @media(min-width: 1600px) {
    margin-left: 12%;

  }

  &_item {

    margin: auto;

    @media(max-width: 1024px) {
      margin: 0 12px;
    }

    @media(min-width: 1024px) {
      width: 100%;

    }

    max-width: 750px;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);

    &_header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      gap: 20px;

      &_title {
        line-height: 160%;
        margin-bottom: 5px;
      }
    }

    &_content {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%;
    }
  }

  &_menu {
    display: inline-flex;
    max-height: 629px;

    padding: 27px 30px;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    margin: 0 12px;
    width: 100%;
    max-width: 750px;

    @media(min-width: 1600px) {
      margin: 0;
      width: 365px;

    }


    &_item {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 25px;

      &_active {
        background-color: var(--Yellow, #F6A80B);
        width: 10px;
        height: 10px;
        flex-shrink: 0;
        border-radius: 50%;
        margin-right: 15px;
      }
    }

    &_name {
      display: flex;
      align-items: center;

      color: var(--Black);
      font-size: 18px;
      font-weight: 500;
      line-height: normal;
    }

    &_period {
      color: var(--grey-text);
      font-size: 16px;
      font-weight: 400;
      line-height: normal;
    }
  }

  &_period {

    color: var(--grey-text);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;

    &_btn {
      color: var(--Yellow, #F6A80B);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      cursor: pointer;
    }

    &_wrp {
      @media(max-width: 1024px) {
        margin: 20px 12px;
      }

      margin-top: 20px;
      display: flex;

      @media(max-width: 600px) {
        flex-wrap: wrap;
      }

      // flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }
  }

  &_week {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>div h3 {
      margin-bottom: 10px;
    }

    width: 100%;

    height: 159px;
    padding: 30px;
    border-radius: 30px;
    background: var(--white);
    background-image: url("../../assets/img/plane-ticket.svg");
    background-repeat: no-repeat;
    background-position: right bottom;

    @media(min-width: 600px) {
      max-width: 365px;
    }
  }

  &_month {

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &>div h3 {
      margin-bottom: 10px;
    }

    width: 100%;

    @media(min-width: 600px) {
      max-width: 365px;
    }

    height: 159px;
    padding: 30px;
    border-radius: 30px;
    background: var(--white);
    background-image: url("../../assets/img/moon.svg");
    background-repeat: no-repeat;
    background-position: right bottom;
  }
}