.header-menu {
    gap: 30px;

    @media(max-width: 1024px) {
        display: none !important;
    }
}

.header-menu li {}

.header-menu li a {
    color: var(--black-text);
    font-feature-settings: 'clig' off, 'liga' off;
    text-decoration: none;
    padding: 39px 0;
    display: flex;
    flex-direction: column;
    position: relative;
}

.header-menu li.active a:after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    -webkit-border-top-left-radius: 50px;
    -webkit-border-top-right-radius: 50px;
    -moz-border-radius-topleft: 50px;
    -moz-border-radius-topright: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: var(--yellow);
    position: absolute;
    bottom: 0px;
}

.header-menu li ul {
    display: none;
}

.header-menu li.has-child a,
.header-menu li.stream a {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
}

.header-menu li.has-child a:after {
    content: "";
    display: block;
    width: 10px;
    height: 7px;
    background: url("../img/arrow.svg") center center no-repeat;
}

.header-menu li.stream a:before {
    content: "";
    display: block;
    width: 8px;
    height: 8px;
    background: var(--red, #FF1C45);
    border-radius: 50%;
}