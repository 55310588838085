.modal {
  width: 100%;

  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px 5px;
    justify-content: left;
    align-items: flex-start;


    &_text_gray {
      color: var(--grey-text);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 10px;
      text-decoration: none;
    }

    &_btn {
      display: flex;
      width: 242px;
      align-self: flex-end;
      padding: 19px 45px;
      margin-top: 30px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 15px;
      background: var(--yellow);
      border: none;
      color: white;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }

    &_link {
      color: var(--yellow, #F6A80B);
      text-decoration: none;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      cursor: pointer;

      &_gray {
        color: #BBB;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        text-decoration: none;
        cursor: pointer;
      }

      &_wrp {
        display: flex;
        justify-content: center;
        gap: 60px;
        padding-top: 30px;
      }
    }
  }




  &_header {
    display: flex;
    justify-content: space-between;
    font-size: 22px;
    font-weight: 700;
    line-height: 160%;
    border-bottom: 1px solid var(--border-grey);;
    padding-bottom: 20px;
    margin-bottom: 30px;
    width: calc(100% - 40px);
  }

  &_input{
    width: 100%;
    vertical-align: text-top;
    text-align: start;
    &_wrp{
      display: flex;
      width: 100%;
      gap: 25px;
    }
  }
  
  &_input textarea{
    height: 200px;
  }

}


.closeBtn {
  z-index: -5;
  position: absolute;
  top: 10px;
  right: 10px;
  outline: none;
  border: none;
  width: 20px;
  height: 20px;
  cursor: pointer;
}