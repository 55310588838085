.stream-shedule {
  max-width: 868px;
  width: 100%;
  height: 760px;
  margin-bottom: 200px;
  background-color: var(--white);
  border-radius: 30px;
  margin-left: 20px;
  padding: 20px;
  @media (max-width: 1279px) {
    margin-bottom: 0px;
  }
  @media (min-width: 1548px) {
    max-width: 100%;
    margin-left: 20px !important;

  }
  margin: auto
  
  /* flex-direction: column; */
}

.stream-shedule .nav-bar {
  @media (max-width: 1600px) {
  }
  width: 100%;
  
  height: 53px;
  overflow-x: auto;
  background-color: var(--white);
  position: relative;
}

.stream-shedule .nav-bar::-webkit-scrollbar {
  height: 8px;
}

.stream-shedule .nav-bar::-webkit-scrollbar-thumb {
  background: var(--grey);
  border-radius: 4px;
}

.stream-shedule .nav-bar .dates-container {
  display: flex;
  white-space: nowrap;
  gap: 25px;
}

.stream-shedule .nav-bar .date-wrapper {
  height: 36px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.stream-shedule .nav-bar li.active div::after {
  position: relative;
  content: "";
  display: block;
  width: 100%;
  height: 4px;
  -webkit-border-top-left-radius: 50px;
  -webkit-border-top-right-radius: 50px;
  -moz-border-radius-topleft: 50px;
  -moz-border-radius-topright: 50px;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  background: var(--yellow);
  position: relative;
  bottom: -10px;
}

.stream-shedule .program-list {
  position: absolute;
  height: 720px;
  @media (max-width: 1279px) {
    margin-bottom: 0px;
    width: 80%;
  }

  width: calc(100% - 25px);

  @media (max-width: 1507px) {
    width: 55%;
  }
  @media (max-width: 1100px) {
    margin-bottom: 0px;
    width: 80% !important;
  }

  @media (min-width: 1508px) {
    width: 95%;
  }
  overflow:scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-top: 53px;
}

.stream-shedule .program-list::-webkit-scrollbar {
  width: 8px;
}

.stream-shedule .program-list::-webkit-scrollbar-thumb {
  background: var(--grey, #d0d0d0);
  border-radius: 4px;
}
