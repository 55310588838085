.show {
    &_title {
        color: var(--Black);
        font-size: 22px;
        font-weight: 800;
        line-height: 140%;
        margin-top: 20px;
        @media (max-width: 1024px) {
            text-align: center;
           }
    
    }
}