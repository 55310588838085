.music {

  &_wrp {
    display: flex;
    justify-content: space-between;
    max-width: 100%;
  }

  &_item {

    position: relative;
    display: flex;
    gap: 15px;
    max-height: 70px;


    &_img {
      height: 70px;
      width: 70px;
      cursor: pointer;
    }

    &_no_img {
      height: 70px;
      min-width: 70px;
      display: flex;
      border-radius: 15px;
      background: var(--Grey-Light, #F4F4F4);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:before {
        position: absolute;
        content: '';
        width: 32.667px;
        height: 32.667px;
        background: center/cover no-repeat url("../../../../../assets/img/noMusic.svg");
      }
    }

    &_name {
      color: var(--grey-text);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }

    &_wrp {
      height: 70px;
      padding: 10px 0;
      display: flex;
      width: 80%;
      flex-direction: column;
      justify-content: space-between;
      &_active {
        width: 100% !important;
        display: flex;
        padding:0;
      }
    }

    &_title {
      color: var(--Black);
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &_btn {
      cursor: pointer;
      &_wrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      margin-left: 24px;
      margin-right: 24px;
      width: 24px;
      height: 24px;
      background: center no-repeat url("../../../../../assets/img/points.svg");
    }
  }

}