.music {
  &_item {
    position: relative;
    display: flex;
    gap: 15px;
    max-width:450px;
    width: 100%;

    &_img {
      height: 70px;
      width: 70px;
      display: flex;
      border-radius: 15px;
      background: var(--Grey-Light, #F4F4F4);
      align-items: center;
      justify-content: center;
      position: relative;
      cursor: pointer;

      &_mask{
        position: relative;
        &_wrp{
          border-radius: 15px;
          height: 70px;
          width: 70px;
        }
        :before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: url("../../../../assets/img/play.svg") no-repeat center center;
          opacity: 0;
          z-index: 1;
          transition: opacity 0.4s ease;
      }
      :after {
        border-radius: 15px;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, .4);
          opacity: 0;
          transition: opacity 0.4s linear;
      }
      :hover:before,
      :hover:after {
          opacity: 1;
      }
      }
    }
    &_name {
      color: var(--grey-text);
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
    }

    &_wrp {
      padding: 10px 0;
      width: 70%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &_active {
        padding: 0;
      }
    }

    &_title {
      color: var(--Black);
      font-size: 18px;
      font-weight: 600;
      line-height: 140%;
      display: table-cell;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
    }

    &_btn {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: 0px;
      z-index: 1;
      cursor: pointer;
      width: 24px;
      height: 24px;
      background: center no-repeat url("../../../../assets/img/points.svg");
    }
  }

}