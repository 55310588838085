.films{
    margin: 20px;
    width: 100%;
}

.form{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.select{
display: flex;
width: 100%;
  
}
