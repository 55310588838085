@import url("reset.css");
@import url("root.css");
@import url("header-menu.scss");

body {
    background: var(--bg-grey, #F9F9F9);
    color: var(--black-text);
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    cursor: default;
    font-family: 'Noto Sans';
    position: relative;
    line-height: 1;
}

* {
    -webkit-tap-highlight-color: transparent;

    &::-webkit-scrollbar {
        width: 8px;
    }

    &::-webkit-scrollbar-button {
        display: none;
    }

    &::-webkit-scrollbar-track-piece {
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--Grey, #D0D0D0);
        border-radius: 18px;
        width: 8px;
        height: 327px;
    }

    &::-webkit-scrollbar-corner {
        background-color: transparent;
    }

    &::-webkit-resizer {
        display: none;
    }
}


h3 {
    color: var(--black-text);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    /* 25.2px */
}

h2 {
    color: var(--black-text);
    font-size: 22px;
    font-weight: 700;
    line-height: 140%;
}

* {
    box-sizing: border-box;
    font-feature-settings: 'clig' off, 'liga' off;
    font-style: normal;
    font-family: 'Noto Sans';
    outline: none;
}

.style__flexbox {
    display: flex;

    @media(max-width: 1024px) {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.style__flexdirection-column {
    flex-direction: column;
}

.style__flex-jc-sb {
    justify-content: space-between;
}

.style__flex-jc-s {
    justify-content: flex-start;
}

.style__flex-jc-e {
    justify-content: flex-end;
}

.style__flex-jc-c {
    justify-content: center;
}

.style__flex-ai-c {
    align-items: center;

}

.style__flex-ai-s {
    align-items: flex-start;
}

header {
    padding: 0 80px;
    width: 100%;
    border-bottom: 1px solid var(--border-grey);
    background: var(--white);

    @media(max-width: 1024px) {
        padding: 0 18px;
    }
}

.header-container {
    min-height: 90px;
    box-sizing: border-box;
}

.left-header {
    @media(max-width: 1024px) {
        justify-content: space-between;
    }

    width: 100%;
    gap: 103px;
}

.right-header {
    gap: 50px;

    @media(max-width: 1024px) {
        display: none !important;
    }
}

.right-header div {
    display: flex;
    align-items: center;
    gap: 15px;
    cursor: pointer;
}

.header-search {}

.header-search:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url("../img/search-ico.svg") center center no-repeat;
}

.header-profile {}

.header-profile:before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background: url("../img/profile-ico.svg") center center no-repeat;
}

.slim-container {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 200px;
    gap: 20px;
}


.container {
    width: 100%;
    max-width: 1520px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 200px;
    gap: 20px;
    @media(max-width: 10024px) {
        margin-bottom: 70px;
    }
}

.horoscope {
    background-color: #1F3596;
    height: 100%;

    min-width: 100%;
    margin: 0;
    padding-top: 20px;
    padding-bottom: 350px;
    background-image: url("../../assets/img/horoscope-back.svg");

    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: -100px;
}

.carousel-block {
    width: 100%;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    position: relative;
    padding: 30px 0;
}

.carousel-content {
    display: grid;
    grid-gap: 20px;
    grid-auto-flow: column;
    margin: auto;
    box-sizing: border-box;
}

.carousel-wrapper {
    overflow: auto;
    scroll-behavior: smooth;
    scrollbar-width: none;
    position: relative;
    padding-left: 30px;
    box-sizing: border-box;
}

.carousel-wrapper::-webkit-scrollbar {
    height: 0;
}

.carousel-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.carousel-item .item__img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    background: var(--yellow);
}

.carousel-item .item__name {
    font-weight: 500;
}

.carousel-btn__prev {
    top: 64px;
    left: 20px;
    display: none;
    transform: rotate(180deg);
}

.carousel-btn__next {
    top: 64px;
    right: 20px;
}

.carousel-btn__prev,
.carousel-btn__next {
    width: 50px;
    height: 50px;
    background: url("../img/carousel-arrow.svg") center center no-repeat;
    position: absolute;
    cursor: pointer;
}

.feed-block {
    gap: 20px;
}

.feed-item {
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.feed-item__img {
    width: 100%;
    height: auto;
    max-height: 559px;
    border-radius: 16px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.feed-item__img.vertical-orientation {
    max-height: 559px;
}

.feed-item__img.horizontal-orientation {
    max-height: 390px;
}

.feed-item__img img {
    width: auto;
    height: 100%;
    object-position: center;
    object-fit: contain;
    position: relative;
    z-index: 1;
}

.feed-img__counter {
    position: absolute;
    border-radius: 39px;
    background: rgba(0, 0, 0, 0.30);
    backdrop-filter: blur(3.6000001430511475px);
    top: 25px;
    left: 25px;
    z-index: 2;
    padding: 3px 12px 0 12px;
}

.feed-img__counter span {
    color: var(--white);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
}

.feed-item .info-author {
    gap: 15px;
}

.feed-item .info-author .author-photo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}

.feed-item .info-author .author-photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.feed-item .info-author .author-name {
    color: var(--black-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
}

.feed-item .info-author .author-subscribe {
    color: var(--yellow, #F6A80B);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.feed-item .info-bookmark {
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50%;
    background: url("../img/Bookmark.svg") center center no-repeat;
    cursor: pointer;
}

.feed-item .info-bookmark.filled {
    background: var(--yellow-light, #FFEAC1) url("../img/Bookmark_filled.svg") center center no-repeat;
}

.feed-item .content-text {
    color: var(--black-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%
}

.feed-item .content-text a {
    color: var(--grey-text);
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
}

.feed-item .content-viewcomments {
    color: var(--grey-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    padding: 15px 0 0 0;
}

.feed-item .content-viewcomments .view-text {
    text-decoration: underline;
    color: var(--grey-text);
}

.feed-item .content-date {
    color: var(--grey-text);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

footer {
    background: var(--white);
    padding: 50px 0 38px;
    box-sizing: border-box;

    @media(max-width: 1024px) {
        padding: 50px 20px 38px;
    }
}

.footer-container {
    width: 100%;
    max-width: 1520px;
    margin: 0 auto;

    @media(max-width: 1600px) {
        padding: 0 15px;
    }
}

.footer-upper__contacts {
    @media(max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

}

.footer-upper {
    display: flex;
    flex-wrap: wrap;
}

.footer-contacts__data {
  
    margin: 50px 0 30px;
    gap: 10px;
    @media(max-width: 1023px) {
        text-align: center;
      }
}

.footer-contacts__data a {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
    text-decoration: none;
}

.footer-contacts__data a[href^="mailto:"] {
    color: var(--black-text);
}

.footer-contacts__data a[href^="tel:"] {
    color: #397FEF;
}

.footer-contacts__social {
    gap: 20px;
}

.footer-contacts__social a {
    width: 40px;
    height: 40px;
}

.footer-upper__menu {
    gap: 80px;

    @media(max-width: 1024px) {
        margin-top: 25px;
        width: 100%;
    }
}

.footer-menu {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer-menu .menu-title a {
    font-size: 22px;
    font-weight: 800;
}

.footer-menu a {
    color: var(--black-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    text-decoration: none;
}

.footer-middle {
    padding: 50px 0;
    border-top: 1px solid var(--border-grey);
    border-bottom: 1px solid var(--border-grey);
    margin: 60px 0 38px;
    gap: 100px;
    display: flex;
    flex-wrap: wrap;

    @media(max-width: 1024px) {
        text-align: center;
    }
}

.footer-middle__qr {
    gap: 15px;
}

.footer-middle__qr img {
    width: 200px;
    height: 200px;
    object-position: center;
    object-fit: contain;
}

.footer-middle__qr span {
    color: var(--grey-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.footer-middle__text {
    gap: 50px;
}

.footer-middle__text .text {
    color: var(--black-text);
    font-size: 22px;
    font-style: normal;
    font-weight: 800;
    line-height: 140%;
    max-width: 448px;
}

.footer-middle__text .app-list {
    gap: 10px;
}

.footer-down {
    display: flex;
    flex-wrap: wrap;
    color: var(--grey-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    
    /* 22.4px */
}

.footer-down .copyright a {
    
    color: var(--blue, #397FEF);
    text-decoration: none;
}

.footer-down .developer a {
    color: var(--black-text);
    text-decoration: none;
}