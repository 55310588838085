.Footer {
    

}

.qrcode{
    background-image: url("../../assets/img/qr-code.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 200px;
    width: 200px;
}
