

.avatar{
  border-radius: 50%;
  overflow: hidden;
  background: rgba(0,0,0,0.1);
  &_img{
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &_no_avatar{
    width: 100%;
    height: 100%;
  }
}