

.last-search-slider {

  .swiper-slide {
   max-width: 365px !important;
   width: 100% !important;
    transition: all 0.2s;
    border-radius: 8px;


    &:hover {
      cursor: pointer;
      opacity: 0.8;
      border-color: transparent;
    }
  }

  .swiper-button-next, .swiper-button-prev {
    @media(min-width: 1669px) {
      display:none;
    }
   
    margin: 0;
    height: 100%;
    top: 0;

    border-radius: 0;

    &::before {
      content: "";
      position: absolute;
      top: 42%;
      transform: translateY(-50%);
      width: 50px;
      height: 50px;
      flex-shrink: 0;
      border-radius: 50%;
      background: #F6A80B;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
      
    }

    &::after {
      position: relative;
    }

    &.swiper-button-disabled {
     // opacity: 0;
    }
  }

  .swiper-button-next {
    right: 20px;
    //background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);

    &::before {
      content: "";
        background: center no-repeat url("../../../../assets/img/right.svg");
    }
  }

  .swiper-button-prev {
left: 20px;
   // background: linear-gradient(-90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 100%);

    &::before {
      content: "";
      background: center no-repeat url("../../../../assets/img/left.svg");
    }
  }
}
