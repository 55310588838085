.swiper-pagination-bullet-active {
  background-color: white;
  opacity: 1 !important;
  height: 10px;
  width: 10px;

}

.swiper-pagination-bullet {
  background-color: white;
  opacity: 0.6;
  height: 10px;
  width: 10px;
}

.swiper-pagination-bullets {
  margin-bottom: 10px;
}
