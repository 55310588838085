.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:before {
    content: '';
    width: 32px;
    height: 32px;
    background-image: url("../../assets/img/heart.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  &_active{
    &:before {
      content: '';
      width: 32px;
      height: 32px;
      background-image: url("../../assets/img/heartFill.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}