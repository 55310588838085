.music {
  &_field {
    margin-top: 30px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    min-height: 370px;
    padding: 30px;

    @media (max-width: 460px) {
      padding: 25px 10px !important;
    }



    &_img {
      height: 180px;
      width: 180px;
      border-radius: 15px;
      object-fit: cover;
    }

    &_no_img {
      height: 180px;
      width: 180px;
      background: var(--Grey-Light, #F4F4F4);
      border-radius: 15px;
    }

    &_item {
      cursor: pointer;

      &_title {
        color: var(--Black);
        font-size: 18px;
        font-weight: 600;
        line-height: 140%;
        margin-top: 15px;

      }

    }


    &_music {
      &_item {
        position: relative;
        display: flex;
        gap: 15px;
        max-height: 70px;

        &_img {
          height: 70px;
          width: 70px;
        }

        &_no_img {
          height: 70px;
          min-width: 70px;
          display: flex;
          border-radius: 15px;
          background: var(--Grey-Light, #F4F4F4);
          align-items: center;
          justify-content: center;

          &:before {
            position: absolute;
            content: '';
            width: 32.667px;
            height: 32.667px;
            background: center/cover no-repeat url("../../assets/img/noMusic.svg");
          }
        }

        &_name {
          color: var(--grey-text);
          font-size: 14px;
          font-weight: 500;
          line-height: 140%;
        }

        &_wrp {
          height: 70px;
          width: 270px;
          padding: 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

        }

        &_title {
          color: var(--Black);
          font-size: 18px;
          font-weight: 600;
          line-height: 140%;
          display: table-cell;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          width: 100%;

        }

        &_btn {
          position: absolute;
          content: '';
          top: 50%;
          transform: translateY(-50%);
          right: 0px;
          z-index: 1;
          cursor: pointer;
          width: 24px;
          height: 24px;
          background: center no-repeat url("../../assets/img/points.svg");
        }
      }

    }




    &_wrp {
      gap: 30px;
      display: grid;
      flex-wrap: wrap;
      grid-template-columns: repeat(6, 1fr);
      @media(max-width: 1024px) {
        grid-template-columns: repeat(5, 1fr);

      }
      @media(max-width: 768px) {
        grid-template-columns: repeat(4, 1fr);

      }
      @media(max-width: 608px) {
        grid-template-columns: repeat(3, 1fr);

      }

      @media(max-width: 451px) {
        grid-template-columns: repeat(2, 1fr);
        justify-content: center;

      }

      &_music {

        gap: 18px;
        display: flex;
        flex-wrap: wrap;
      }
    }

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      &_btn {
        text-decoration: none;
        cursor: pointer;
        color: var(--Yellow, #F6A80B);
        font-size: 16px;
        font-weight: 600;
        line-height: 140%;
      }
    }

    &_title {
      color: var(--Black);
      font-size: 22px;
      font-weight: 700;
      line-height: 160%;
    }
  }
}