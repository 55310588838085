.stream {
  width: 100%;
  max-width: auto;
  margin: 0 auto;
  display: flex;
justify-content: center;
  align-items: flex-start;
  @media (max-width: 1601px) {
    flex-wrap: wrap;
  }
}

@media (min-width: 1601px) {
  .stream {
    max-width: 1600px;
    /* Увеличиваем максимальную ширину для десктопа */
  }
}

.stream-pleer {
  margin-top: 10px;
  @media (max-width: 1507px) {
    padding: 12px;
  }
 
}

.wrp {
  width: 100%;
  position: relative;
  @media (min-width: 1508px) {
    max-width: 622px;
  }
  
  @media (max-width: 1507px) {
    padding: 0 20px;
    max-width: 100%;
    margin-top: 50px;
  }
}

.stream-block{
  width: 100%;
  max-width: 898px;
  margin: 0 sauto;
}