.wrap {
  width: fit-content;
  margin: auto;
  background: var(--white);
  border-radius: 20px;
  max-width: 1264px;
  width: 100%;

}

.container {

  display: grid;
  grid-gap: 50px;
  grid-template-columns: 267px 1fr;
  min-height: 800px;
  @media(max-width: 1256px) {
    min-height: 500px;
  }

  @media(max-width: 668px) {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}

.navbar {
  position: relative;
  height: 100%;
  padding: 30px 0;
  border-right: 1px solid var(--border-grey);;

  @media(max-width: 668px) {
    display: none;
  }
}

.content {
  width: 100%;
  padding: 30px 30px 50px 0;


  @media(max-width: 1256px) {
    padding: 0 15px 0 15px;
    margin: 0 0 30px 0;
  }

  h2 {
    margin-bottom: 30px;
  }
}