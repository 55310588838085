.wrap {
  margin-top: 120px;
  position: relative;

  @media(max-width: 523px) {
    margin: auto;
    margin-top: 30px;
    width: 360px;
  }

  // overflow-x: auto !important;
}

.title {
  font-weight: 600;
  font-size: 20px;
  line-height: 1.2;
  margin: 0 0 10px;

  @media(min-width: 576px) {
    font-size: 24px;
  }
}

.last_search_wrp {
  display: flex;
  width: 100%;
  overflow-x: hidden;
}

.last_search_scroll {
  display: flex;
  gap: 16px;
  width: auto;
  overflow-x: auto;

  /* хром, сафари */
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  /* ie 10+ */
  & {
    -ms-overflow-style: none !important;
  }

  /* фф (свойство больше не работает, других способов тоже нет)*/
  & {
    overflow: -moz-scrollbars-none !important;
  }
}

.slider {
  opacity: 1;
}

.img {
  max-width: 365px;
  width: 100%;
  height: 220px;
  border-radius: 20px;
  object-fit: cover;
}

.prev {
  cursor: pointer;
  position: absolute;
  left: -70px;
  display: flex;

  &::before {
    height: 50px;
    width: 50px;
    content: "";
    background: center no-repeat url("../../../../assets/img/left.svg");
  }
}

.next {
  cursor: pointer;
  position: absolute;
  right: -70px;
  display: flex;

  &::before {
    height: 50px;
    width: 50px;
    content: "";
    background: center no-repeat url("../../../../assets/img/right.svg");
  }
}


.swiper {
  &_navigation {
    @media(max-width: 1668px) {
      display: none;
    }

    position: absolute;
    top: 35%;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }
}

.slide {
  //max-width: 365px;

  &_name {
    margin-top: 15px;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }

  &_gray {
    margin-top: 5px;
    color: var(--grey-text);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
  }
}

.comment {
  display: flex;
  max-width: 365px;
  position: relative;

  &_info {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &_date {
    position: absolute;
    bottom: 20px;
    right: 20px;
    color: var(--grey-text);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    /* 19.6px */
  }

  height: 244px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 20px;
  background: var(--white);

  &_text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-height: 160%;
    /* 25.6px */
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  &_avatar {
margin-right: 15px;
    width: 50px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 50px;
    background-color: lightgrey;
  }

  /* аа */
  box-shadow: 4px 4px 28px 15px rgba(51, 41, 31, 0.02);
}