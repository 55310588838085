.service {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-items: flex-start;
    justify-content: center;

    &_item {
        border-radius: 30px;
        border: 1px solid var(--border-grey);
        background: var(--white);
        padding: 30px;
        max-width: 878px;
        width: 100%;

        @media(min-width: 1520px) {
            max-width: 622px;
        }


        &_title {
            color: var(--Black);
            margin-bottom: 30px;
            font-size: 28px;
            font-weight: 700;
            line-height: 160%;
        }

        &_text {
            line-height: 160%;
        }

        &_img {
            max-width: 878px;
            width: 100%;
            border-radius: 30px;
            object-fit: cover;

        }

        &_btn {
            margin-top: 50px;
        }
    }





}