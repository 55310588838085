.btn {
  z-index: 2;
  border-radius: 15px;
  background: var(--yellow);
  display: flex;
  border: none;
  height: 60px;
  letter-spacing: 1px;
  border: 1px solid var(--yellow);
  &:hover {
    color: var(--white) !important;
    background: var(--yellow) !important;
    border: 1px solid var(--yellow) !important;
  }

  color: var(--white);
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  color: white;
  padding: 19px 45px;
  justify-content: center;
  align-items: center;

  &_disabled {
    letter-spacing: 1px;
    display: flex;
    border: none;
    height: 60px;
    display: inline-flex;
    padding: 19px 45px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 15px;
    background: var(--2-grey);
    color: var(--grey-text);
    font-size: 16px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }
}