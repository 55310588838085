

  .swiper{
    position: relative;
    border-radius: 20px;
    //height: 472px;
    width: 100% !important;
    min-width: 365px;


  
    &_img{
      padding: 0 5px;
        width: 100%;
    }
    &_navigation{
        position: absolute;
        top: 47.5%;
        left: 12px;
        right: 12px;
        display: flex;
        justify-content: space-between;
        z-index: 1;
      }
  
  }
  