.all{
  
  &_wrp{
    margin: auto;
    margin-top: 30px;
    border-radius: 30px;
    border: 1px solid var(--border-grey);
    background: var(--white);
    padding: 30px;
    gap: 18px;
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 451px) {
      justify-content: center;

    }
  }
}